@import "../modules/all";

// Base Styles
//––––––––––––––––––––––––––––––––––––––––––––––––––
// NOTE
// html is set to 62.5% so that all the REM measurements throughout Summit
// are based on 10px sizing. So basically 1.5rem = 15px :)

*, *:before, *:after {
  box-sizing: border-box;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
  font-display: swap;
}
@font-face {
  font-family: 'hero-mobile';
  src: url('/assets/fonts/hero-mobile.eot');
  src: url('/assets/fonts/hero-mobile.eot?awqv3m#iefix') format('embedded-opentype'),
  url('/assets/fonts/hero-mobile.woff2?awqv3m') format('woff2'),
  url('/assets/fonts/hero-mobile.ttf?awqv3m') format('truetype'),
  url('/assets/fonts/hero-mobile.woff?awqv3m') format('woff'),
  url('/assets/fonts/hero-mobile.svg?awqv3m#freeup') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html, body {
  height: 100%;
  margin: 0 auto;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-size: 17px; // currently ems cause chrome bug misinterpreting rems on body element
  line-height: 1.18;
  font-weight: normal;
  font-family: $base-font-family;
  color: $black;
  max-width: 2000px;
  margin: 0 auto;
  overflow-x: hidden;

  @media (#{$larger-than-mobile}) {
    font-size: 13px;
    line-height: 1.18;
  }

  @media (#{$larger-than-desktop}) {
    font-size: 22px;
  }
}

// Links
//––––––––––––––––––––––––––––––––––––––––––––––––––

a {
  color: $link-color;
  text-decoration: none;
  font-weight: 700;

  &:hover {
    color: $primary-color-hover;
  }
  &:active {
    color: $primary-color-selected;
  }
}

// Misc
//––––––––––––––––––––––––––––––––––––––––––––––––––

hr {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 1px solid $light-grey;
}

audio,
canvas,
img,
video {
  vertical-align: middle;
}

[class*="col"] {

  img {
    max-width: 100%;
  }
}

input {
  &[type="email"],
  &[type="number"],
  &[type="search"],
  &[type="text"],
  &[type="tel"],
  &[type="url"],
  &[type="password"] {
    font-family: $base-font-family;
    height: 50px;
    padding: 6px 10px; // The 6px vertically centers text on FF, ignored by Webkit
    background-color: #fff;
    border: 1px solid lighten($border-color, 8.8%);
    border-radius: 0px;
    box-shadow: none;
    box-sizing: border-box;
    display: inline-block;
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled){
  background-color: #fff!important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label, .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
  color: $primary-color!important;
}