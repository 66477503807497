// Breakpoints
$larger-than-mobile: "min-width: 426px" !default;
$larger-than-phablet: "min-width: 640px" !default;
$larger-than-tablet: "min-width: 750px" !default;
$larger-than-desktop: "min-width: 1025px" !default;
$larger-than-desktophd: "min-width: 1200px" !default;
$desktop-1399-break-point: "1399.9px";
$desktop-large-screen: "1612px";
$desktop-1600-break-point: "1600px";
$desktop-wide-screen: "1920px";
$mobile-min: "426px";
$phablet-min-width: "640px";
$phablet-min-width-plus-one: "641px";
$phablet-min-width-minus-one: "639px";
$phablet-min-width-minus: "639.9px";
$mobile-max-width-minus: "479.9px";
$mobile-min-width-minus: "414px";
$desktop-min-width-minus: "1024.9px";
$tablet-min-width: "751px";
$tablet-min-width-minus: "749.9px";
$ipad-min-width: "835px";
$ipad-min-width-minus: "833.9px";
$desktop-min-width: "1025px";
$ipad-max-width: "519.9px";
$iphone-max-width: "475.9px";
// Responsive Grid System
$grid__bp-sm: 426;
$grid__bp-md: 640;
$grid__bp-lg: 750;
$grid__bp-xl: 1025;
$grid__bp-max: 1360;
$grid__cols: 12;

// Colors
$white-grey: #ebebeb !default;
$lightest-grey: #e2e2e2 !default;
$light-grey: #f2f2f2 !default;
$grey: #B1B1B1 !default;
$grey-100: #D1D1D1 !default;
$medium-grey: #999999 !default;
$dark-grey: #666666 !default;
$darkest-grey: #333333 !default;
$disabled-grey: #727272 !default;
$grey-transparent-02: #4b4b4b05 !default;
$grey-transparent-04: #4b4b4b0a !default;
$grey-transparent-06: #4b4b4b0f !default;
$grey-transparent-07: #4b4b4b12 !default;
$grey-transparent-10: #4b4b4b1a !default;
$grey-transparent-15: #4b4b4b26 !default;
$grayscale-Shades-700: #4B4B4B !default;
$med-grey: #D1D1D1 !default;
$mat-grey: #A1A1A1 !default;
$border-grey: #ddd !default;
$black: #000000 !default;
$key-grey: #757575 !default;
$grayscale-Shades-600: #5F5F5F !default;
$grayscale-Gray: #929292 !default;
$grayscale-Shades-800: #373737 !default;
$grey-opacity-20: #CCCCCC;
$grey-50: #E9E9E9 !default;
$grey-100: #D1D1D1 !default;
$grey-200: #B9B9B9 !default;
$grey-400: #8B8B8B !default;
$white: #ffffff !default;
$light-black: #090909!default;

$primary-color: #B91B2C !default;
$primary-color-dark: #980E20 !default;
$primary-color-mid: #EE4C4E!default;
$primary-color-light: #FFDEDE !default;
$primary-color-hover: #00AFAA !default;
$primary-color-selected: #D6323B !default;
$primary-color-mid-light: #FF5959!default;
$primary-opacity-10: #FEE !default;
$primary-opacity-40: #FFBDBD !default;
$primary-opacity-60: #FF9B9B !default;
$secondary-color: #66CFCC !default;
$secondary-color-dark: #33BFBB !default;
$secondary-color-darkest: #007874;
$secondary-color-transparent: #0078740d !default;
$secondary-color-light: #F2FBFB;
$secondary-color-much-darkest: #004341!default;
$tertiary-color-hover: #E5F7F7!default;;
$third-color:#000000!default;
$third-color-dark:#333333 !default;
$tertiary-color: #572362!default;
$disabled:#8B8B8B!default;
$disbaled-text-color:#F2F2F2!default;
$input-outline: #FFF7F7!default;
$hover-outline: #F2F2F2!default;
$border-disabled:#CCCCCC!default;
$tooltip-color:#99DFDD!default;
$light-gray:#B9B9B9!default;
$background-color-light: #E9F6F5 !default;
$light-alert: #FDF4F4!default;
$light-green: #F4FCF3!default;
$green-opacity-20: #CCE4E3!default;
/* todo change*/

$menu-bar-bg: #dbd5fa !default;
$menu-bar-font: #000000 !default;
$white: #ffffff !default;

$border-color: #979797 !default;
$link-color: $primary-color !default;
$text-color: $black !default;
$text-color-light: $medium-grey !default;
$input-border: #C6C6C6 !default;


$footer-bg-light: #e3e1e3 !default;
$footer-bg-alt-light: #c3c1c3 !default;
$footer-bg: #042025 !default;
$footer-bg-alt: #446369 !default;
$header-bg: #7a888d !default;

$notification-bg: #fc9c46 !default;
$notification-bg-alt: #beb4f8 !default;
$notification-bg-critical: $primary-color !default;
$notification-bg-critical-alt: $menu-bar-bg !default;

$success: #0EB864 !default;
$warning: #f5a623 !default;
$alert: #D94A47 !default;
$error: #D92121 !default;
$green: #1cc40e!default;

$red: #d0021b !default;
$light-red: #da2b1f !default;
$mid-red: #d22119 !default;
$blue: #0064c9 !default;
$light-blue: #389afc !default;
$orange: #ff8514 !default;

// Font weights
$light: 300;
$regular: 400;
$bold: 700;

// Base Font
$base-font-family: "calibri", sans-serif !default;

// Misc
$assets-path: "/assets/img/" !default;

$sg-steel: #a5a3a3;
$sg-light-grey: #EAEAEA;

$grey: #dbd8d8;