.button {
  @include font-size(18px);
  line-height: 18px;
  background-image: none;
  border: 0px solid transparent;
  border-radius: 24px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-weight: 800;
  letter-spacing: normal;
  margin: 0;
  max-width: 100%;
  outline: none;
  padding: 12px;
  text-align: center;
  vertical-align: middle;
  word-wrap: break-word;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 96px;
  min-height: 40px;
  padding: 0 24px;
  background-color: transparent;
  @media (max-width: $phablet-min-width-minus) {
    font-size: 16px;
    line-height: 16px;
  }

  i {
    font-size: 21px;
    line-height: 60px;
  }

  a {
    font-family: $base-font-family;
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: 0;
    }
  }
  &.small {
    min-width: 80px;
  }

  @include button-theme($text-color);

  &.primary {
    @include button-theme($primary-color);
  }
  &.primary:focus {
    @include button-theme($primary-color-dark);
  }
  &.primary:hover {
    @include button-theme($primary-color-hover);
    box-shadow: 0px 2px 2px 0px #4B4B4B12;
  }
  &.primary:disabled {
    @include button-theme($disabled);
    color: $disbaled-text-color;
    box-shadow: none;
  }
  &.secondary {
    @include button-theme(#ffffff);
    border: 1px solid $primary-color;
    color: $primary-color;
    border-color: $primary-color;
  }
  &.secondary:hover {
    border: 1px solid $primary-color-hover;
    @include button-theme(#ffffff);
    box-shadow: 0px 2px 2px 0px #4B4B4B1A;
    color: $primary-color-hover;
    border-color: $primary-color-hover;
  }
  &.secondary:disabled {
    border: 1px solid $disabled;
    @include button-theme($light-grey);
    color: $disabled;
    box-shadow: none;
    border-color: $disabled;
  }
  &.tertiary {
    border: none;
    color: $primary-color;
    background-color: transparent;
    font-family: $base-font-family;
  }
  &.tertiary:hover {
    color: $primary-color-hover;
  }
  &.tertiary:focus {
    color: $primary-color-dark;
  }
  &.tertiary:disabled {
    border: none;
    color: $disabled;
    box-shadow: none;
  }
  &.disabled {
    border: 1px solid $disabled;
    @include button-theme($light-grey);
    color: $disabled;
    box-shadow: none;
  }
  &.tab {
    padding: 16px 12px;
    border: 1px solid $light-grey;
    @include button-theme($light-grey);
    color: #000000;
    box-shadow: none;
    border-color: $light-grey;
    border-radius: 8px;

    &.focus {
      border: 1px solid $primary-color;
      @include button-theme($input-outline);
      border-color: $primary-color;
    }
  }
  &.tab:focus {
    border: 1px solid $primary-color;
    @include button-theme($input-outline);
    border-color: $primary-color;
  }
  &.tab:hover {
    border: 1px solid $primary-color;
    @include button-theme($input-outline);
    border-color: $primary-color;
  }
}

input {

  &[type="submit"],
  &[type="reset"],
  &[type="button"] {
    display: inline-block;
    padding: 15px 30px;
    color: white;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.1rem;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid $border-color;
    cursor: pointer;
    box-sizing: border-box;
  }
}

input {

  &[type="submit"]:hover,
  &[type="reset"]:hover,
  &[type="button"]:hover {
    color: white;
    border-color: lighten($dark-grey, 33.3%);
    outline: 0;
  }
}

input {

  &[type="submit"]:focus,
  &[type="reset"]:focus,
  &[type="button"]:focus {
    color: white;
    border-color: lighten($dark-grey, 33.3%);
    outline: 0;
  }
}

button[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
}
