.page-section {
  padding: 0 6.5%;

  @media (min-width: 1920px) {
    padding: 0 200px;
  }

  @media(max-width: $desktop-min-width-minus) {
    padding: 0 24px;
  }

  @media(max-width: 479.9px) {
    padding: 0 16px;
  }
}

.section-padding {
  padding: 40px calc(64px + ((100vw - 1025px) / 100) * 15.2);

  @media (min-width: $desktop-wide-screen) {
    padding: 48px 200px;
  }

  @media(max-width: $desktop-min-width) {
    padding: 40px 24px;
  }

  @media(max-width: $mobile-max-width-minus) {
    padding: 40px 16px;
  }
}

.info-banner {
  border-radius: 8px;
  padding: 24px;

  @media(max-width: $phablet-min-width-minus) {
    padding: 16px;
  }

  &.default-background {
    background: #F2FBFB;
    box-shadow: 0px 5px 5px 0px rgba(0, 120, 116, 0.05), 0px 2px 6px 0px rgba(75, 75, 75, 0.05), 0px 4px 8px 0px rgba(75, 75, 75, 0.08);
  }

  &.warning-background {
    background: #FFF9F3;
    box-shadow: 0px 5px 5px 0px rgba(255, 133, 20, 0.05), 0px 2px 6px 0px rgba(75, 75, 75, 0.05), 0px 4px 8px 0px rgba(75, 75, 75, 0.08);
  }

  &.alert-background {
    background: #FDF4F4;
    box-shadow: 0px 5px 5px 0px rgba(217, 33, 33, 0.05), 0px 2px 6px 0px rgba(75, 75, 75, 0.05), 0px 4px 8px 0px rgba(75, 75, 75, 0.08);
  }

  &.success-background {
    background: #F4FCF3;
    box-shadow: 0px 5px 5px 0px rgba(28, 196, 14, 0.05), 0px 2px 6px 0px rgba(75, 75, 75, 0.05), 0px 4px 8px 0px rgba(75, 75, 75, 0.08);
  }

  .banner-box {
    .flex {
      display: flex;
      gap: 12px;

      @media (max-width: $phablet-min-width-minus) {
        gap: 8px;
      }

      img {
        width: 24px;
        height: 24px;

        @media (max-width: $phablet-min-width-minus) {
          width: 20px;
          height: 20px;
        }
      }

      .banner-content {
        .title {
          margin-bottom: 8px;
          font-weight: bold;
        }

        .desc-a {
          margin-bottom: 2px;
        }

        .desc-b {
          margin-bottom: 8px;
        }

        .last {
          margin-bottom: 0;
        }

        b {
          font-family: $base-font-family;
          font-weight: 700;
        }

        .actions {
          margin-top: 16px;
          display: flex;
          gap: 16px;
          flex-wrap: wrap;

          button {
            min-width: 105px;
            font-family: $base-font-family;
            margin: 0 !important;

            @media (max-width: $phablet-min-width-minus) {
              min-width: 100px;
            }

            &.tertiary {
              padding: 8px;
            }
          }
        }
      }
    }
  }
}

.store-locator-validation {
  color: $alert;
  text-transform: capitalize;
  letter-spacing: normal;
  font-family: $base-font-family;
  font-size: rem-calc(20px);
  font-weight: normal;
}

#toast-container.toast-bottom-center>div,
#toast-container.toast-top-center>div {
  text-align: left;
  width: 100%;

  @media (#{$larger-than-tablet}) {
    width: 75%;
    margin: 25px auto auto;
  }

  @media (#{$larger-than-desktop}) {
    width: 50%;
    margin: 25px auto auto;
  }

}

#toast-container>.toast-error {
  background-image: url('https://upload.wikimedia.org/wikipedia/commons/thumb/7/7f/Dialog-error.svg/1024px-Dialog-error.svg.png') !important;
}

.internal-page-margins {
  margin-top: 60px;

  @media (#{$larger-than-tablet}) {
    margin-top: 80px;
  }
}

.content-section {
  padding: 10px 0;

  @media (#{$larger-than-tablet}) {
    padding: 60px 0;
  }
}

hr {
  margin-top: 30px;
  margin-bottom: 30px;

  @media (#{$larger-than-tablet}) {
    margin-top: 76px;
    margin-bottom: 76px;
  }
}

hr.primary {
  border-top: 1px solid $primary-color;
}

hr.secondary {
  border-top: 1px solid $secondary-color;
}

hr.thirdly {
  border-top: 1px solid $third-color;
}

hr.gray {
  border-top: 1px solid $grey;
}

$right-space-vars: (
  5 right right,
  10 right right,
  15 right right,
  20 right right,
  25 right right,
  30 right right,
  35 right right,
  40 right right,
  45 right right,
  50 right right,
  55 right right,
  60 right right,
  65 right right,
  70 right right,
  75 right right,
  80 right right,
  85 right right,
  90 right right,
  95 right right,
  100 right right
);

$top-space-vars: (
  5 top top,
  10 top top,
  15 top top,
  20 top top,
  25 top top,
  30 top top,
  35 top top,
  40 top top,
  45 top top,
  50 top top,
  55 top top,
  60 top top,
  65 top top,
  70 top top,
  75 top top,
  80 top top,
  85 top top,
  90 top top,
  95 top top,
  100 top top
);

$bottom-space-vars: (
  5 bottom bottom,
  10 bottom bottom,
  15 bottom bottom,
  20 bottom bottom,
  25 bottom bottom,
  30 bottom bottom,
  35 bottom bottom,
  40 bottom bottom,
  45 bottom bottom,
  50 bottom bottom,
  55 bottom bottom,
  60 bottom bottom,
  65 bottom bottom,
  70 bottom bottom,
  75 bottom bottom,
  80 bottom bottom,
  85 bottom bottom,
  90 bottom bottom,
  95 bottom bottom,
  100 bottom bottom
);

@include generate-spacing-classes(); // left comes by default
@include generate-spacing-classes($right-space-vars);
@include generate-spacing-classes($top-space-vars);
@include generate-spacing-classes($bottom-space-vars);

.loading-hidden {
  background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;

  div {
    visibility: hidden;
  }
}

.loading-hidden-transparent {
  height: 70px;
  margin-top: -10px;
  background: transparent image-url("ajax-loader-transparent.gif");
  background-repeat: no-repeat;
  background-size: 50px 50px;
  background-position: center center;

  div {
    visibility: hidden;
  }
}

label {
  &.validation-message {
    @extend .validation-message;
    margin-top: 6px;
    margin-bottom: 12px;
  }
}

.validation-message {
  color: $alert;
  text-transform: capitalize;
  letter-spacing: normal;
  font-family: $base-font-family;
  font-size: rem-calc(14px);
  font-weight: normal;
}

.alt-bg {
  background-color: $light-grey;
}

.primary-bg {
  background-color: $primary-color;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-10 {
  margin-bottom: 10px;
}